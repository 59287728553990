import React from "react";
import cSharp from "../assets/cSharp.png";
import css from "../assets/css.png";
import github from "../assets/github.png";
import html from "../assets/html.png";
import java from "../assets/java.png";
import lambdaAWS from "../assets/lambdaAWS.png";
import mysql from "../assets/mysql.png";
import typescript from "../assets/typescript.png";

const Experience = () => {
  const techs = [
    { id: 1, src: html, title: "HTML", style: "shadow-orange-500" },
    { id: 2, src: cSharp, title: "C#", style: "shadow-blue-500" },
    { id: 3, src: css, title: "CSS", style: "shadow-blue-500" },
    { id: 4, src: github, title: "GitHub", style: "shadow-gray-400" },
    { id: 5, src: java, title: "Java", style: "shadow-red-500" },
    { id: 6, src: lambdaAWS, title: "AWS Lambda", style: "shadow-orange-500" },
    { id: 7, src: mysql, title: "MySQL", style: "shadow-blue-600" },
    {
      id: 8,
      src: typescript,
      title: "TypeScript",
      style: "shadow-white",
    },
  ];

  return (
    <div
      name="experience"
      className="bg-gradient-to-b from-black to-gray-800 
    w-full h-screen justify-center "
    >
      <div
        className="max-w-screen-lg mx-auto p-4 flex flex-col
      w-full h-full text-white"
      >
        <div>
          <p
            className="text-4xl font-bold border-b-4 
          border-red-800 p-2 inline m"
          >
            Experience
          </p>
          <p className="py-6">These are the technologies I've worked with</p>
        </div>

        <div
          className="w-full grid grid-cols-2 sm:grid-cols-3
        gap-8 text-center py-8 px-12 sm:px-0"
        >
          {techs.map(({ id, src, title, style }) => (
            <div
              key={id}
              className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
            >
              <img src={src} alt="" className="w-20 mx-auto" />
              <p className="mt-4">{title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experience;

import React from "react";
import {
  faGithub,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "./NavBar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Contact = () => {
  return (
    <div name="contact" className="w-full h-screen bg-gray-200 p-4 text-black">
      <div className="flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-red-800">
            Contact
          </p>
          <p className="py-6">Submit the form below to get in touch with me</p>
        </div>

        <div className="flex justify-center items-center">
          <form
            action="https://getform.io/f/92e4d017-60ca-4031-8d58-65a1f0c771fc"
            method="POST"
            className=" flex flex-col w-full md:w-1/2 "
          >
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              className="p-2 bg-white border-2 rounded-md text-black focus:outline-none"
            />
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              className="my-4 p-2 bg-white border-2 rounded-md text-black focus:outline-none"
            />
            <textarea
              name="message"
              placeholder="Enter your message"
              rows="10"
              className="p-2 bg-white border-2 rounded-md text-black focus:outline-none"
            ></textarea>

            <button className="text-white bg-gradient-to-b from-gray-800 to-gray-600 px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300">
              Send
            </button>
          </form>
        </div>
        <div className="h-20 align-bottom text-center text-sm">
          <div>
            <span>Edit by Juan Mateos ○ </span>
            <span>Design by Samara Gordillo</span>
            <div>
              <ul className="flex flex-row justify-center">
                <li>
                  <a
                    className=" hover:scale-110  duration-200"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/in/juan-a-mateos/"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="hover:text-red-800 text-gray-500"
                    />
                  </a>
                </li>
                <li>
                  <a
                    className="hover:scale-110 duration-200"
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/One-create5"
                  >
                    <FontAwesomeIcon
                      icon={faGithub}
                      className="hover:text-red-800 px-5 text-gray-500"
                    />
                  </a>
                </li>
                <li>
                  <a
                    className="hover:scale-110 duration-200"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/lucky_conquerorr/"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="hover:text-red-800 text-gray-500"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="mx-auto p-4 flex-col justify-center 
    w-full h-screen bg-gradient-to-b bg-gray-100 text-black"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-red-800">
            About
          </p>
        </div>

        <p className="text-sm sm:text-xl">
          I served in the United States Marine Corps for nine years. My
          experience in the military has given me the discipline and work ethic
          to excel in any environment, as well as solid problem-solving skills
          that I apply to every project. While on Active Duty, I pursued my B.S.
          in Computer Science and attended the Microsoft Software and Systems
          Academy, emphasizing Cloud Application Development. The MSSA is an
          advanced 19-week technical training course developed by Microsoft
          teamed with Embry Riddle Aeronautical University (ERAU).
        </p>

        <br />

        <p className="text-sm sm:text-xl">
          I'm a Software Engineer Apprentice at Amazon, where I work alongside
          some of the best and brightest minds in the industry, gaining
          knowledge and experience in the entire software development lifecycle,
          including scoping, design, code, testing, documentation, deployment,
          and maintenance.
        </p>
        <br />
        <p className="text-sm sm:text-xl">
          I enjoy working on back-end development using technologies like Java,
          TypeScript, and NoSQL and utilizing AWS Services and Technologies. In
          my free time, you'll find me playing with my dog, at the gym, hiking,
          or occasionally playing video games
        </p>
      </div>
    </div>
  );
};

export default About;
